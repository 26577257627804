
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";

@Component
export default class App extends Vue {
  // dynamische Menü Items
  navItems = [
    { title: "Rechner", icon: "mdi-car", link: "/" },
  ];
}
