
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import { parseISO, format, differenceInDays } from "date-fns";

@Component
export default class Rechner extends Vue {
  laufleistung = 8000;
  laufzeit = 6;
  startDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substring(0, 10);
  verlangert = false;
  mehrKm = false;
  setEndDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substring(0, 10);
  kmProTag = 1;
  kmAmEnde = this.laufleistung;

  modal = false;
  endModal = false;

  mounted(): void {
    // query parameter handling
    console.log("Query params", this.$route.query);
    this.laufleistung = this.$route.query.laufleistung
      ? parseInt(this.$route.query.laufleistung as string)
      : this.laufleistung;
    this.laufzeit = this.$route.query.laufzeit
      ? parseInt(this.$route.query.laufzeit as string)
      : this.laufzeit;
    this.startDate = this.$route.query.startDate ? (this.$route.query.startDate as string) : this.startDate;
    this.verlangert = this.$route.query.endDate != null;
    // if query parameter is passed, the thing is null. If not passed, it is undefined. So I need to do a type specific check. God I hate it.
    this.mehrKm = this.$route.query.mehrKm === null;
    this.setEndDate = this.$route.query.endDate ? (this.$route.query.endDate as string) : this.setEndDate;
  }

  setCorrectEndDate(): void {
    let endDateObj = parseISO(this.startDate);
    endDateObj.setMonth(endDateObj.getMonth() + this.laufzeit);
    this.setEndDate = endDateObj.toISOString().substring(0, 10);
  }

  fixLaufleistung(): void {
    if (!this.mehrKm) {
      this.kmAmEnde = this.laufleistung
    }
  }

  /**
   * Returns the date formatted with date-fns with the format "dd.MM.yyyy"
   */
  computedDateFormattedDatefns(date: string): string {
    return date ? format(parseISO(date), "dd.MM.yyyy") : "";
  }

  /**
   * Calculate the difference between two dates in days with one day added
   * for the correct kilometerstand value at the end date
   * @param startDate start	date of the contract
   * @param endDate end date of the contract
   */
  wrongDifference(startDate: Date, endDate: Date): number {
    return differenceInDays(endDate, startDate) + 1;
  }

  /**
   * Get the end date of the contract
   * Remark: The end date is the day the start date + the months of the contract
   * If the contract was extended, the new date from the input filed is selected
   * @param startDate start date of the contract
   */
  endeDate(startDate: Date): Date {
    var endDate = new Date(startDate);
    if (this.verlangert) {
      endDate = parseISO(this.setEndDate);
    } else {
      endDate.setMonth(startDate.getMonth() + this.laufzeit);
      endDate.setDate(startDate.getDate() - 1);
    }
    console.log("endeDate", endDate);
    return endDate;
  }

  /**
   * Calculate the kilometers per day
   * @param days the number of days in the contract
   * @param mineage the number of kilometers in the contract
   */
  kmProTagMethod(days: number, mineage: number): number {
    return mineage / days;
  }

  getExtraDays(startDate: Date, endDate: Date): number {
    var originalEndDate = new Date(startDate.getTime());
    originalEndDate.setMonth(startDate.getMonth() + this.laufzeit);
    console.log("start", startDate, "end", originalEndDate);
    originalEndDate.setDate(startDate.getDate() - 1);
    let diff = differenceInDays(endDate, originalEndDate)
    console.log("Tage mehr", diff);
    return diff;
  }

  sollKilometer(): number {
    let startDateObj = parseISO(this.startDate);
    let endDateObj = this.endeDate(startDateObj);
    let todayObj = new Date();
    let extraDays = this.getExtraDays(startDateObj, endDateObj);

    if (this.mehrKm) {
      this.kmAmEnde = this.laufleistung + extraDays * this.kmProTag;
    }

    let tageInsg = this.wrongDifference(startDateObj, endDateObj);
    this.kmProTag = this.kmProTagMethod(tageInsg, this.kmAmEnde);
    let vergangeneTage = this.wrongDifference(startDateObj, todayObj);

    console.log(
      "startDate",
      startDateObj,
      "\ntoday",
      todayObj,
      "\nendDate",
      endDateObj
    );

    console.log(
      "tageInsgesamt",
      tageInsg,
      "\nkmProTag",
      this.kmProTag,
      "\nvergangeneTage",
      vergangeneTage
    );
    return vergangeneTage * this.kmProTag;
  }

  async saveConfig(): Promise<void> {
    var url = window.location.origin + "?startDate=" + this.startDate + "&laufzeit=" + this.laufzeit + "&laufleistung=" + this.laufleistung
    if (this.verlangert) {
      url += "&endDate=" + this.setEndDate;

      if (this.mehrKm) {
        url += "&mehrKm";
      }
    }

    await navigator.clipboard.writeText(url);
  }
}
